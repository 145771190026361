var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "users" },
    [
      _vm._l(_vm.computed_users, function (user) {
        return _c(
          "div",
          {
            key: user.id,
            staticClass: "user",
            class: { selected: _vm.selected.includes(user.id) },
            on: { click: () => _vm.handleClick(user) },
          },
          [
            _c("avatar", {
              attrs: { user: user, hide_tooltip: !_vm.show_tooltips },
            }),
          ],
          1
        )
      }),
      _vm.available_users.length > 0 && !_vm.fixed_list
        ? _c(
            "el-dropdown",
            {
              attrs: { trigger: "click", placement: "right" },
              on: { command: _vm.handleCommand },
            },
            [
              _c(
                "div",
                { staticClass: "add" },
                [
                  _c("svgicon", {
                    staticClass: "icon",
                    attrs: { name: "plus-circle" },
                  }),
                ],
                1
              ),
              _c(
                "el-dropdown-menu",
                { attrs: { slot: "dropdown" }, slot: "dropdown" },
                _vm._l(_vm.available_users, function (user) {
                  return _c(
                    "el-dropdown-item",
                    { key: user.id, attrs: { command: user.id } },
                    [_vm._v(" " + _vm._s(user.name) + " ")]
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }